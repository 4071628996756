body {
    font-size: 20px;
}

.users {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.user {
    padding: 2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.user > div {
    margin-top: 1rem;
    color: #ccc;
}
